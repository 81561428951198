<template>
  <ModalBase :isOpen="!!checkoutUI.duplicateBooking">
    <template #modalTitle>
      {{ $t("duplicate_modal.just_to_be_sure_have_you_already_made_this_booking") }}
    </template>

    <template #modalMessage>
      <p>
        {{ $t("duplicate_modal.we_have_detected_a_possible_duplicate_booking") }}
      </p>

      <p class="fw-bold">
        {{ $t('duplicate_modal.there_is_a_booking_in_our_system_for', {
          email: email,
          date: tourDate,
          productTitle: tourTitle,
        }) }}
      </p>

      <p>
        {{
          $t("duplicate_modal.would_you_like_us_to_resend")
        }}
      </p>
    </template>

    <template #buttons>
      <button
        type="button"
        class="btn p-2 btn-success"
        @click="handleResendBooking"
      >
        {{ $t("duplicate_modal.resend_my_existing_booking") }}
      </button>
      <button
        type="button"
        class="btn p-2 btn-outline-dark"
        @click="handleMakeNewBooking"
      >
        {{ $t("duplicate_modal.no_make_a_new_reservation") }}
      </button>
    </template>
  </ModalBase>

  <ModalBase
    :isOpen="checkoutUI.confirmationEmailSent"
    :icon="MailSentIcon"
  >
    <template #modalTitle>
      {{ $t("duplicate_modal.we_have_resent_the_booking") }}
    </template>

    <template #modalMessage>
      <p>
        {{
          $t("duplicate_modal.your_existing_booking_has_been_resent",
          { email: email })
        }}
      </p>
      <p>
        {{
          $t("duplicate_modal.also_check_your_spam")
        }}
      </p>
    </template>

    <template #buttons>
      <button
        type="button"
        class="btn p-2 btn-success"
        @click="goHome"
      >
        {{ $t("duplicate_modal.resend_my_existing_booking") }}
      </button>
      <button
        type="button"
        class="btn p-2 btn-outline-dark"
        @click="handleMakeNewBooking"
      >
        {{ $t("duplicate_modal.no_make_a_new_reservation") }}
      </button>
    </template>
  </ModalBase>

</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useCheckoutStore } from "@/stores/checkoutStore";
import { useLocaleStore } from "@/stores/localeStore";

import ModalBase from "@/components/ModalBase.vue";
import MailSentIcon from '@/assets/images/icon_mail_sent.svg';

defineProps<{
  tourTitle: string;
  tourDate: string | null;
  email: string;
}>();

const { locale } = useLocaleStore();

const checkoutStore = useCheckoutStore();
const { checkoutUI } = storeToRefs(checkoutStore);
const { processBooking, resendConfirmationEmail } = checkoutStore;

const handleResendBooking = () => {
  if (!checkoutUI.value.duplicateBooking) {
    console.log("Error: missing duplicate booking");
    return;
  }

  resendConfirmationEmail(checkoutUI.value.duplicateBooking.baja_id);
};

const handleMakeNewBooking = () => {
  processBooking();
};

const goHome = () => {
  window.location.href = `/${locale}/`;
};

</script>

<style scoped lang="scss">
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-header, .modal-footer {
  border: none;
}

.modal-header {
  color: #2d508e;

  h5 {
    font-weight: 600;
  }
}

</style>