<template>
  <TopBar :hasMenu="false" />

  <PageBanner 
    v-if="isOrderFound && orderData"
    :topSlider="orderData.topSlider" 
    :showSpotlight="false" 
    class="d-none d-sm-block" 
  />

  <main class="d-flex flex-column p-2 p-md-4">
    <div class="container my-4 flex-grow-1">
      <div v-if="isOrderFound && orderData">
        <h1 class="fw-bold fs-3 mb-4 text-lg-center">{{ $t('booking_confirmation.title') }}</h1>

        <div class="row p-4 rounded-3 border mx-auto booking-info-container">
          <BookingInfo 
            :tourTitle="orderData.tourTitle" 
            :tourDate="formattedTourDate" 
            :tourTime="orderData.tourTime"
            :emailAddress="orderData.emailAddress" 
            :adults="orderData.adults" 
            :children="orderData.children" />

          <ShareBookingForm />
        </div>
      </div>

      <div v-else>
        <h1 class="fw-bold fs-3 mb-4">{{ $t('booking_confirmation.error_title') }}</h1>
        <p class="text-danger fs-5 fw-bold">{{ $t('booking_confirmation.error_subtitle') }}</p>
        <p>
          {{ $t('booking_confirmation.contact_us_1') }}
          <a :href="contactUrl" class="text-primary">{{ $t('booking_confirmation.contact_us_2') }}</a>
          {{ $t('booking_confirmation.contact_us_3') }}
        </p>
        <p>{{ $t('booking_confirmation.contact_us_4') }}</p>
      </div>
    </div>

    <div class="container my-4">
      <MediaPartners :mediaPartners="mediaPartners" />
    </div>
  </main>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';
import { useDates } from '@/composables/useDates';

import TopBar from '@/components/TopBar.vue';
import PageBanner from "@/components/PageBanner.vue";
import BookingInfo from "@/components/BookingInfo.vue";
import ShareBookingForm from "@/components/ShareBookingForm.vue";
import MediaPartners from "@/components/MediaPartners.vue";

const props = defineProps<{
  isOrderFound: boolean;
  locale: string;
  mediaPartners: {
    postTitle: string,
    postImage: string,
  }[],
  orderData: {
    tourDate: string;
    tourTime: string;
    adults: number;
    children: number;
    emailAddress: string;
    topSlider: string;
    tourTitle: string;
  };
}>();

const { setLocale } = useLocaleStore();
const { formatDisplayDate } = useDates();

setLocale(props.locale);

const formattedTourDate = computed(() => {return formatDisplayDate(new Date(props.orderData.tourDate)) || ''});
const contactUrl = computed(() => `${window.location.origin}/${props.locale}/contact`);

</script>

<style scoped>
main {
  min-height: 75vh;
}

.booking-info-container {
  background-color: #f9f9f9;
  max-width: 900px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
</style>
