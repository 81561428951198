<template>
  <div
    v-if="isOpen"
    class="modal d-block align-items-center justify-content-center position-fixed top-0 left-0 right-0 bottom-0"
  >
    <div class="modal-dialog overflow-y-auto w-100 position-relative top-50 m-auto">
      <div class="modal-content px-4 rounded-4">
        <Icon
          v-if="showClose"
          type="far"
          name="xmark"
          size="1x"
          class="xclose position-absolute p-1 cursor-pointer"
          @click="$emit('close')"
        />

        <div class="text-center position-relative">
          <img
            v-if="icon"
            :src="icon"
            alt="Modal Icon"
            class="modal-icon"
          />
        </div>

        <div v-if="modalTitle" class="modal-header text-center fs-5 fw-bold justify-content-center px-0">
          <slot name="modalTitle" />
        </div>

        <div class="modal-body px-0 py-2 text-center">
          <slot name="modalMessage" />
        </div>

        <div class="modal-footer text-center py-0 px-5 d-flex flex-column gap-2 justify-content-center align-items-stretch">
          <slot name="buttons">
            <button
              class="btn btn-default bg-blue px-3"
              @click="$emit('close')"
            >
              {{ $t("okay") }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import defaultIcon from '@/assets/images/icon_modal_failed.svg';

defineProps({
  isOpen: Boolean,
  showClose: {
    type: Boolean,
    default: false,
  },
  modalTitle: {
    type: String,
    default: '',
  },
  modalMessage: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: defaultIcon,
  },
  buttons: {
    type: Array,
    default: [],
  },
});

defineEmits(['close']);
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, .5);
  z-index: 1050;

  .modal-dialog {
    transform: translateY(-50%);
  }

  .modal-content {
    padding: 32px 36px;
  }
}

.xclose {
  top: 10px;
  right: 10px;
}

.modal-header, .modal-footer {
  border: none;
}
.modal-header {
  padding-top: 18px;
  padding-bottom: 0px;
}
</style>
