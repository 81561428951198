<template>
  <!-- TO DO IN PAD-2761 -->
  <div class="col-lg">
    <div class="d-flex mb-3">
      <Email class="me-2" />
      <span class="fw-bold">{{ $t('booking_confirmation.share_booking') }}</span>
    </div>

    <div class="row">
      <div class="col-md mb-3">
        <input class="form-control" :placeholder="$t('booking_confirmation.email')">
      </div>

      <div class="col-md mb-3">
        <input class="form-control" :placeholder="$t('name')">
      </div>
    </div>

    <button class="btn btn-success rounded-3 mb-3">
      {{ $t('booking_confirmation.add_friend') }}
    </button>

    <textarea 
      class="form-control mb-3"
      :placeholder="$t('message')" 
      rows="2"
    ></textarea>

    <button class="btn btn-success rounded-3">
      {{ $t('send') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import Email from '@/components/Icons/Email.vue';

</script>

<style scoped>
</style>
