import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faCheck,
  faChevronLeft as faChevronLeftRegular,
  faChevronRight as faChevronRightRegular,
  faStar as faStarRegular,
  faStarHalfStroke as faStarHalfStrokeRegular,
  faUser as faUserRegular,
  faMessageExclamation as faMessageExclamationRegular,
  faXmark as faXmarkRegular,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faTiktok,
  faYoutube,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons'

import {
  faSearch,
  faAngleDown,
  faStar,
  faStarHalfStroke,
  faUser,
  faBars,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
} from '@fortawesome/pro-solid-svg-icons'

// Add the icons to the library
library.add(
  faSearch,
  faAngleDown,
  faCheck,
  faStar,
  faStarRegular,
  faStarHalfStroke,
  faStarHalfStrokeRegular,
  faUser,
  faUserRegular,
  faBars,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronRightRegular,
  faChevronLeftRegular,
  faChevronDown,
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faTiktok,
  faYoutube,
  faPinterest,
  faMessageExclamationRegular,
  faXmarkRegular
)

export default FontAwesomeIcon