import { ref } from "vue";
import { defineStore } from "pinia";
import type { Attribute, AttributePrice, Price } from "@/types/prices";

interface ProductOptions {
  selectedPax: number;
  selectedChildrenPax: number;
  minMaxParticipants: {
    min: number;
    max: number;
  };
  minMaxChildrenParticipants: {
    min: number;
    max: number;
  };
  attributes: Attribute[];
  selectedAttributes: Record<string, number>;
}

interface PriceRange {
  min: number;
  max: number;
}

export const useProductSelectionsStore = defineStore('productSelectionsStore', () => {
  // State
  const productOptions = ref<ProductOptions>({
    selectedPax: 1,
    selectedChildrenPax: 0,
    minMaxParticipants: { min: 0, max: 0 },
    minMaxChildrenParticipants: { min: 0, max: 0 },
    attributes: [],
    selectedAttributes: {},
  });

  // Helper Functions
  const getPrice = (prices: Price[], pax = 0): number => {
    if (!prices || !prices.length) return 0;

    const price = prices.find(p => pax >= p.min_participants && pax <= p.max_participants);
    return price ? parseFloat(price.selling_price) : 0;
  };

  const getTotalLineItemPrice = (prices: Price[], pax = 0): number => {
    if (!prices || pax === 0) return 0;

    return pax * getPrice(prices, pax);
  };

  const getAttributePrice = (prices: AttributePrice[], attrCount = 0): number => {
    if (!prices || !prices.length) return 0;

    if (prices.length === 1) return prices[0].selling_price;
    const price = prices.find(p => attrCount >= p.amount && attrCount <= p.amount);
    return price ? price.selling_price : 0;
  };

  const getTotalLineItemAttributePrice = (prices: AttributePrice[], attrCount = 0): number => {
    if (!prices || attrCount === 0) return 0;
    return attrCount * getAttributePrice(prices, attrCount);
  };

  const calculatePriceRange = (prices: Price[]): PriceRange => {
    if (!prices || !prices.length) return { min: 0, max: 0 };

    return {
      min: prices.reduce((min, price) => Math.min(min, price.min_participants), prices[0].min_participants),
      max: prices.reduce((max, price) => Math.max(max, price.max_participants), prices[0].max_participants),
    };
  };

  return {
    // State
    productOptions,

    // Helper Functions
    getPrice,
    getTotalLineItemPrice,
    getAttributePrice,
    getTotalLineItemAttributePrice,
    calculatePriceRange,
  };
});