<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 15h-1v-6h2v6h-1Zm0-8.2c-.66 0-1.2-.54-1.2-1.2s.54-1.2 1.2-1.2 1.2.54 1.2 1.2-.54 1.2-1.2 1.2Z" fill="currentColor"/>
  </svg>
</template>

<script setup>
defineProps({
  color: {
    type: String,
    default: "#252525",
  },
});
</script>
