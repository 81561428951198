import { computed, ref } from "vue";
import { defineStore } from "pinia";

interface Customer {
  first_name: string;
  last_name: string;
  customer_email: string;
  customer_phone: string;
  email_confirm: string;
}

interface ValidationErrors {
  first_name: boolean;
  last_name: boolean;
  customer_email: boolean;
  customer_phone: boolean;
  email_confirm: boolean;
}

type CustomerField = keyof Omit<Customer, 'email_confirm'>;

export const useCustomerStore = defineStore('customerStore', () => {
  // State
  const customer = ref<Customer>({
    first_name: '',
    last_name: '',
    customer_email: '',
    customer_phone: '',
    email_confirm: '',
  });

  const formSubmitted = ref(false);
  const isPhoneValid = ref(false);
  const errors = ref<ValidationErrors>({
    first_name: false,
    last_name: false,
    customer_email: false,
    customer_phone: false,
    email_confirm: false,
  });

  // Getters
  const isValid = computed(() =>
    Object.values(errors.value).every(error => !error)
  );

  // Actions
  const validateField = (fieldName: CustomerField) => {
    errors.value[fieldName] = !customer.value[fieldName];
  };

  const handlePhoneValidation = (phoneObject: { valid: boolean; number: string }) => {
    isPhoneValid.value = phoneObject.valid;
    if (phoneObject.valid) {
      customer.value.customer_phone = phoneObject.number;
    }
  };

  const handlePhoneBlur = () => {
    errors.value.customer_phone = !isPhoneValid.value;
  };

  const handleEmailMatch = () => {
    errors.value.email_confirm = customer.value.email_confirm !== customer.value.customer_email;
  };

  const validate = () => {
    formSubmitted.value = true;
    const fields: CustomerField[] = ['first_name', 'last_name', 'customer_email', 'customer_phone'];
    fields.forEach(validateField);
    return isValid.value;
  };

  return {
    // State
    customer,
    formSubmitted,
    isPhoneValid,
    errors,

    // Getters
    isValid,

    // Actions
    validate,
    validateField,
    handlePhoneValidation,
    handlePhoneBlur,
    handleEmailMatch,
  };
});
