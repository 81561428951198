<template>
  <ModalBase
    :isOpen="isOpen"
    :showClose="true"
    @close="closeModal"
  >
    <template #modalTitle>

    </template>

    <template #modalMessage>
      <div class="modal-message mb-2">
        {{ modalMessage }}
      </div>
    </template>

    <template #buttons>
      <button
        class="btn btn-success px-3"
        @click="closeModal"
      >
        {{ $t("okay") }}
      </button>
    </template>
  </ModalBase>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useErrorStore } from "@/stores/errorStore";
import ModalBase from "@/components/ModalBase.vue";

const errorStore = useErrorStore();
const { showError: isOpen, message: modalMessage } = storeToRefs(errorStore);

const closeModal = () => {
  errorStore.clearError();
};
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, .5);

  .modal-content {
    border-radius: 16px;
  }

  .modal-vertical-centered {
    transform: translateY(150px);
  }

  .xclose {
    top: 10px;
    right: 10px;
  }

  button.bg-blue {
    background-color: #2C508E;
    color: #fff;

    &:hover {
      background-color: #234072;
    }
  }
}

.modal-icon {
  width: 48px;
  height: 48px;
}
</style>
