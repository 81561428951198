<template>
  <div class="col-lg">
    <div class="d-flex align-items-center mb-3">
      <Info class="me-2" />
      <span class="fw-bold">{{ $t('booking_confirmation.confirm_booking') }}</span>
    </div>

    <div class="mt-3"> 
      <ul class="mt-3">
        <li>{{ tourTitle }}</li>
        <li>{{ tourDate }} {{ $t('at') }} {{ tourTime }}</li>
        <li>
          {{ adults }} {{ $t('adults') }}
          <span v-if="children > 0"> {{ children }} {{ $t('children') }}</span>
        </li>
        <li>{{ $t('booking_confirmation.email') }}: {{ emailAddress }}</li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import Info from '@/components/Icons/Info.vue';

defineProps<{
  tourTitle: string;
  tourDate: string;
  tourTime: string;
  emailAddress: string;
  adults: number;
  children: number;
}>();

</script>

<style scoped>
</style>
