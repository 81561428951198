<template>
  <TopBar />
  <PageBanner
    :showSpotlight="true"
    :mainTitle="mainTitle"
    :topSlider="topSlider"
    class="d-none d-sm-block"
  />

  <main class="p-4 p-md-5">
    <div class="container mt-4 overflow-hidden">
      <div v-if="bannerImage" class="banner-image d-none d-sm-block">
        <img
          :src="bannerImage"
          alt="Inner banner"
          loading="lazy"
        />
      </div>
      <div class="main-content w-100" v-html="mainContent"></div>
    </div>

    <div v-if="isProposalPage"> <ProposalForm :cities="cities" /> </div>

    <div class="mt-lg-5">
      <MediaPartners :mediaPartners="mediaPartners" />
    </div>
  </main>
</template>

<script lang="ts" setup>
import { useLocaleStore } from "@/stores/localeStore";

import TopBar from "@/components/TopBar.vue";
import PageBanner from "@/components/PageBanner.vue";
import ProposalForm from "@/components/ProposalForm.vue";
import MediaPartners from "@/components/MediaPartners.vue";

const props = defineProps<{
  locale: string,
  mainContent: string,
  mainTitle: string,
  bannerImage: string,
  topSlider: any,
  isProposalPage: boolean,
  cities: string[],
  mediaPartners: {
    postTitle: string,
    postImage: string,
  }[],
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

</script>

<style scoped>
.banner-image img {
  float: right;
  margin: 0 0 40px 40px;
}

.main-content {
  &::v-deep(h1) {
    margin-bottom: 20px;
    color: #2d508e;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  &::v-deep(h2) {
    margin-bottom: 20px;
    color: #2d508e;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  &::v-deep(h3), &::v-deep(h4) {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #2d508e;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  &::v-deep(ul) {
    list-style: disc;
    font-weight: 400;
    line-height: 24px;
  }

  &::v-deep(li) {
    color: #474747;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &::v-deep(p) {
    margin-bottom: 16px;
    color: #474747;
    font-size: 16px;
    font-weight: 400;
  }

  &::v-deep(a) {
    color: #2d508e;
    text-decoration: none;
  }
}
</style>